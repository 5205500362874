const config = {
    page_name: "Sikkema Software",
    
    route: {
	    //landing: "http://localhost:6110",
	    landing:   "https://wwww.sikkemasoftware.nl",
	    base:      "/",
	    overview:  "/overview",
	    portfolio: "/portfolio",
	    blog:      "/blog",
	    about:     "/about",
	    contact:   "/contact",
    },
};
  

export default config;


