import { React, useState, useEffect } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import config from "./config"
import {Helmet} from "react-helmet";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {Container, Row, Col, Grid} from 'react-bootstrap';

const About = () =>  {
    useEffect(() => {
        console.log("About - useEffect")
    }, {});

    return (
      <>
      <Helmet>
	<title>About</title>
      </Helmet>
      <div className="content-container">
        <Container>
          <div className="content-item">
	    We are specialized in building ai products and integrating them into a simple to use dashboard. See our <a href={config.route.portfolio}>portfolio for more examples </a>
          </div>
        </Container>
      </div>
      </>
    )

}


export default About
