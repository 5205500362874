

import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom"
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import React from "react";
import { FlagIcon } from '@heroicons/react/24/outline'
import "leaflet/dist/leaflet.css";
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import {Icon} from 'leaflet'

import L from "leaflet";
import API from '../api/api'
import Router from '../api/router'
import config from '../config'




const Map = (params) => {
    const navigate = useNavigate()
    const location = useLocation()
    const router   = new Router(navigate, location)
    const [settings, setSettings] = useState({coordinates: {latitude: 0, longitude: 0},
                                              display_name: "",
                                              setModal: null,
                                              errorHandler: null})

    console.log("LOCATION", settings.coordinates.latitude, settings.coordinates.longitude)

    const [investments, setInvestments]   = useState([
	{id: 1,
	 name: "Sikkema Software B.V.",
	 address: "Roomsterweg 15",
	 zip: "9351 TD",
	 City: "Leek",
	 tel:  "06-18312635",
	 email: "sikkemasoftware@gmail.com",
	 location: {
	     lat: 53.148173,
	     lon: 6.365683,
	 },
	}
    ])

    useEffect(() => {
    	settings.coordinates  = params.coordinates
        settings.display_name = params.display_name
        settings.errorHandler = params.errorHanlder


    }, {});


  const customIcon = new L.Icon({//creating a custom icon to use in Marker
    iconUrl: FlagIcon,
    iconSize: [25, 35],
    iconAnchor: [5, 30]
  });

  function MapView() {
    let map = useMap();
    map.setView([settings.coordinates.latitude, settings.coordinates.longitude], map.getZoom());
     //Sets geographical center and zoom for the view of the map
    return null;
  }

  const showPopup = (investment) => {
        return (
		<Marker position={[investment.location.lat, investment.location.lon]} icon={new Icon({iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41]})} >
		<Popup><div>
		<table><tbody>
		<tr><td>{investment.name}</td></tr>
		<tr><td>{investment.address}</td></tr>
		<tr><td>{investment.zip} {investment.city}</td></tr>
		<tr><td>{investment.email}</td></tr>
		<tr><td>{investment.tel}</td></tr>
		</tbody></table>
		</div></Popup>
		</Marker>
	)
    }

  return (
    <MapContainer
      classsName="map"
      center={[settings.coordinates.latitude, settings.coordinates.longitude]}
      zoom={5}
      scrollWheelZoom={true}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> 
        contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
	  {investments.map((investment, index) => showPopup(investment))}

      )}
      <MapView />
    </MapContainer>
  );
}


export default Map;
