import { React, useState, useEffect } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import config from "./config"
import Map from './Components/Map'
import {Helmet} from "react-helmet";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {Container, Row, Col, Grid} from 'react-bootstrap';

import Logo     from './media/images/logo.png'
import {
    XMarkIcon,
} from '@heroicons/react/24/outline'



const Contact = () =>  {
    console.log("Portfolio")
    const navigate = useNavigate()
    const location = useLocation()

    const [showModal, setShowModal]       = useState(false)
    const [current,   setCurrent]         = useState(null)
    const [errorMessage, setErrorMessage] = useState("")

    useEffect(() => {
        console.log("Portfolio - useEffect")
    }, {});


    const onChange = (val) => {
    }

    const onChangeAny = (val, attr) => {
    }

    const onKeyDown = (event) => {
        if (event.keyCode === 27) {
            closeModal();
        }
    };

    const onClickOutside = (event) => {
        //if (modal && modal.contains(event.target)) return;
        closeModal();
    };

    const toggleScrollLock = () => {
        document.querySelector('html').classList.toggle('scroll-lock');
    };


    const openModal = (current) => {
        setCurrent(current)
        setShowModal(true)
    };

    const closeModal = () => {
        setShowModal(false)
    };


    const renderModal = () => {
        if (current === null) {
            return (<div> </div>)
        }

        return (
    	  <Modal
            show={showModal}
            onHide={closeModal}
            centered
          >
	  <Row>
	    <Col md="12">
              <Modal.Header>
                <Modal.Title>{current.name}</Modal.Title>
              </Modal.Header>
	    </Col>
	  </Row>

          <Row>
            <Col md="9" className="">
	    </Col>
            <Col md="2" className="modal-button clickable" onClick={closeModal}>
	      Close
	    </Col>
            <Col md="1" className="modal-button clickable" onClick={closeModal}>
              <XMarkIcon className="w-5 h-9 text-black-900" alt="Close"/>
	    </Col>
	  </Row>
          <Modal.Body className="modal-body">
	    <Row>
	      <Col md="12"><table width="100%"><tbody width="100%">
                <tr>
                  <td className="modal-col"> Company </td>
                  <td className="modal-col">{current.name}</td>
                </tr>
                <tr>
                  <td className="modal-col"> Address </td>
                  <td className="modal-col">{current.street}</td>
                </tr>
                <tr>
                  <td className="modal-col"> </td>
                  <td className="modal-col">{current.postcode} {current.city}</td>
                </tr>
                <tr>
                  <td className="modal-col"> Phone </td>
                  <td className="modal-col">{current.tel}</td>
                </tr>
                <tr>
                  <td className="modal-col"> Email </td>
                  <td className="modal-col">{current.email}</td>
                </tr>
		</tbody></table>
	      </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
          </Modal>
        )
    }


    

    const center = {latitude: 53.148173,
		    longitude: 6.365683}


    return (
        <>
	    <Helmet>
	      <title>Contact Us</title>
	    </Helmet>
	
	    <div className="content-container">
            <Container>
  	      <Row className=""> 
	        <Col className="content-container"> 
                  <div className="content-item"> 
	            Please reach out to us at sikkemasoftware at gmail.com. 
	          </div>
	        </Col>
	      </Row>
  	      <Row className=""> 
	        <Col md={6} className="content-container center text-center"> 
	          <div className="content-item">
	            <Map coordinates={center}
	              display_name="Current Investments"
	              setModal={openModal}
	              errorHandler={setErrorMessage}
                    />
	          </div>
	        </Col>
	      </Row>
            </Container>
	    </div>
        </>
	)

}


export default Contact
